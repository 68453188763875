<template>
  <footer class="footer ">
  
    <div class="container">
      <div class="row align-items-center justify-content-lg-between">
   
      <div class="col-lg-12">
        <div class="copyright text-center text-lg-center text-muted">
          © {{ year }}, made with <i class="fa fa-heart heart"></i> by<a
            class="font-weight-bold ml-1"
            target="_blank"
            rel="noopener"
            >YMT</a
          >
    
          for a better web.
        </div>
      </div>
    </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
