import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
// import axios from "axios";
// Example pages
// import ListUserPage from "@/views/Examples/UserManagement/ListUserPage.vue";

// const PasswordReset = () =>
//   import(/* webpackChunkName: "password" */ "@/views/Password/Reset.vue");
// const PasswordEmail = () =>
//   import(/* webpackChunkName: "password" */ "@/views/Password/Email.vue");
Vue.use(VueRouter);

//import middleware
// import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

function guestt(to, from, next) {
  if (localStorage.users) {
    next({
      name: "dashboard",
    });
  } else {
    next();
  }
}

function guard(to, from, next) {
  if (localStorage.users) {
    next();
  } else {
    next({
      name: "login",
    });
  }
}

const router = new VueRouter({
  //   linkExactActiveClass: "active",
  //   mode: "hash",
  // base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      redirect: "dashboard",
      component: DashboardLayout,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("./views/Dashboard.vue"),
          beforeEnter: guard,
        },
        {
          path: "/icons",
          name: "icons",
          component: () => import("./views/Icons.vue"),
        },

        {
          path: "/word/add",
          component: () => import("./views/Word/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/word/add/:id",
          name: "Add utility",
          component: () => import("./views/Word/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/word",
          component: () => import("./views/Word/View.vue"),
          beforeEnter: guard,
        },

        {
          path: "/word/edit/:id",
          name: "Edit utility",
          component: () => import("./views/Word/Edit.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level",
          component: () => import("./views/Level/View.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level/add",
          component: () => import("./views/Level/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level/edit/:id",
          name: "Edit level",
          component: () => import("./views/Level/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/sub-level/add/:idd",
          name: "Add Sublevel",
          component: () => import("./views/SubLevel/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/sub-level/:id",
          name: "View sub level",
          component: () => import("./views/SubLevel/View.vue"),
          beforeEnter: guard,
        },

        {
          path: "/sub-level/edit/:id/:idd",
          name: "Edit sub level",
          component: () => import("./views/SubLevel/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/category",
          name: "View category",
          component: () => import("./views/Category/View.vue"),
          beforeEnter: guard,
        },
        {
          path: "/category/edit/:id",
          name: "Edit category",
          component: () => import("./views/Category/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/category/add",
          component: () => import("./views/Category/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/word/category/:id",
          name: "Word category",
          component: () => import("./views/Category/list.vue"),
          beforeEnter: guard,
        },
        {
          path: "/user",
          component: () => import("./views/User/View.vue"),
          beforeEnter: guard,
        },
        {
          path: "/user/add",
          component: () => import("./views/User/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/user/edit/:id",
          name: "Edit user",
          component: () => import("./views/User/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/challenge/:id",
          name: "View challenge",
          component: () => import("./views/Challenge/View.vue"),
          beforeEnter: guard,
        },

        {
          path: "/challenge-add",
          name: "Add challenge",
          component: () => import("./views/Challenge/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/challenge/edit/:id",
          name: "Edit challenge",
          component: () => import("./views/Challenge/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/option/:id",
          name: "View option",
          component: () => import("./views/Option/View.vue"),
          beforeEnter: guard,
        },
        {
          path: "/activity/view/:id",
          name: "View activity",
          component: () => import("./views/Activity/View.vue"),
          beforeEnter: guard,
        },
        {
          path: "/option/edit/:id",
          name: "Edit option",
          component: () => import("./views/Option/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/activity/add1/:idd",
          name: "Add activity",
          component: () => import("./views/Activity/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/activity/add/:idd",
          name: "Add activity1",
          component: () => import("./views/Activity/Level1/Add1.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level1/activity/view/:idd",
          name: "View activity1.4",
          component: () => import("./views/Activity/Level1/View.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level1/activity/add",
          name: "add activity1.4",
          component: () => import("./views/Activity/Level1/Add2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/activity/add/2/:idd",
          name: "Add activity2",
          component: () => import("./views/Activity/Add2.vue"),
          beforeEnter: guard,
        },
        // {
        //   path: "/activity/add/:id",
        //   name: "Edit activity1",
        //   component: () => import("./views/Activity/Add1.vue"),
        //   beforeEnter: guard,
        // },

        // {
        //   path: "/activity-edit1/:id",
        //   name: "Edit activity1",
        //   component: () => import("./views/Activity/Add1.vue"),
        //   beforeEnter: guard,
        // },

        {
          path: "/activity/view1/:id",
          name: "View activity1",
          component: () => import("./views/Activity/View1.vue"),
          beforeEnter: guard,
        },

        // {
        //   path: "/activity/edit/:id",
        //   name: "Edit activity",
        //   component: () => import("./views/Activity/Add.vue"),
        //   beforeEnter: guard,
        // },

        {
          path: "/question/add",
          component: () => import("./views/Question/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/question/add/1.3.4/:acivitityID",
          name: "Question add1",
          component: () => import("./views/Question/Level1/Add1.vue"),
          beforeEnter: guard,
        },
        {
          path: "/question/edit1/:id/:acivitityID",

          name: "Edit question1",
          component: () => import("./views/Question/Level1/Add1.vue"),
          beforeEnter: guard,
        },
        {
          path: "/question/edit/:id",
          name: "Edit question",
          component: () => import("./views/Question/Add.vue"),
          beforeEnter: guard,
        },

        // {
        //   path: "/activity/edit2/:id",
        //   name: "Edit activity2",
        //   component: () => import("./views/Activity/Add2.vue"),
        //   beforeEnter: guard,
        // },

        {
          path: "/activity/view2/:id",
          name: "View activity2",
          component: () => import("./views/Activity/View2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/question/add/2/:acivitityID",
          name: "Question add2",
          component: () => import("./views/Question/Add2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/question/edit2/:id/:acivitityID",
          name: "Edit question2",
          component: () => import("./views/Question/Add2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/activity/add/3/:idd",
          name: "Add activity3",
          component: () => import("./views/Activity/Level3/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/activity/view3/:id",
          name: "Level3 view activity",
          component: () => import("./views/Activity/Level3/View.vue"),
          beforeEnter: guard,
        },
        // {
        //   path: "/activity/edit3/:id",
        //   name: "Edit activity3",
        //   component: () => import("./views/Activity/Level3/Add.vue"),
        //   beforeEnter: guard,
        // },
        {
          path: "/question/add3/:acivitityID",
          name: "Question add3",
          component: () => import("./views/Question/Level3/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/question/edit3/:id/:acivitityID",
          name: "Edit question3",
          component: () => import("./views/Question/Level3/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/activity/add/:idd",
          name: "Add activity4",
          component: () => import("./views/Activity/Level3/Add1.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/activity/view/:id",
          name: "Level3.2 view activity",
          component: () => import("./views/Activity/Level3/View1.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level3/activity/edit/:id",
          name: "Edit activity3.2",
          component: () => import("./views/Activity/Level3/Add1.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/question/add/:acivitityID",
          name: "Question add4",
          component: () => import("./views/Question/Level3/Add1.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/question/edit/:id/:acivitityID",
          name: "Edit question4",
          component: () => import("./views/Question/Level3/Add1.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/activity/add1",
          name: "Add question5",
          component: () => import("./views/Activity/Level3/View2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/activity/edit1",
          name: "edit question5",
          component: () => import("./views/Activity/Level3/View2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/activity/add2/:idd",
          name: "Add activity5",
          component: () => import("./views/Activity/Level3/Add2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/question/edit2/:id/:acivitityID",
          name: "Edit question3.3.4",
          component: () => import("./views/Question/Level3/Add2.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level3/question/add1/:acivitityID",
          name: "Question add5",
          component: () => import("./views/Question/Level3/Add2.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level3/activity/view3/:idd",
          name: "View activity3.4",
          component: () => import("./views/Activity/Level3/View3.vue"),
          beforeEnter: guard,
        },

        {
          path: "/activity/add/4/:idd",
          name: "View activity2.3.1",
          component: () => import("./views/Activity/Level2/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/activity/add/5/:idd",
          name: "View activity2.2.3",
          component: () => import("./views/Activity/Level2/Add1.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/activity/add3.4",
          name: "Add activity3.4",
          component: () => import("./views/Activity/Level3/Add3.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level3/activity/edit3.4/:id",
          name: "Edit question3.4",
          component: () => import("./views/Activity/Level3/Add3.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level3/question/add2/:acivitityID",
          name: "Question add3.4",
          component: () => import("./views/Question/Level3/Add4.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level3/question/edit3/:id/:acivitityID",
          name: "Edit questionn3.4",
          component: () => import("./views/Question/Level3/Add4.vue"),
          beforeEnter: guard,
        },

        {
          path: "/number/add",
          name: "Add Number",
          component: () => import("./views/Activity/Number/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/number/edit/:id",
          name: "Edit number",
          component: () => import("./views/Activity/Number/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level2/question/add/:acivitityID",
          name: "Question add2.3",
          component: () => import("./views/Question/Level2/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level2/question/add1/:acivitityID",
          name: "Question add2.2.3",
          component: () => import("./views/Question/Level2/Add1.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level2/question/edit/:id/:acivitityID",
          name: "Edit question2.3.1",
          component: () => import("./views/Question/Level2/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level2/question/edit1/:id/:acivitityID",
          name: "Edit question2.2.3",
          component: () => import("./views/Question/Level2/Add1.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/activity/add/:idd",
          name: "Add activity4.1.1",
          component: () => import("./views/Activity/Level4/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/question/add/:acivitityID",
          name: "Add question4.1.1",
          component: () => import("./views/Question/Level4/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/activity/add/level1/:idd",
          name: "Add activity1.3.2",
          component: () => import("./views/Activity/Level1/Add.vue"),
          beforeEnter: guard,
        },

        // {
        //   path: "/level4/activity/edit/:id",
        //   name: "Edit question4.1.1",
        //   component: () => import("./views/Activity/Level4/Add.vue"),
        //   beforeEnter: guard,
        // },

        {
          path: "/level4/activity/edit/:id/:acivitityID",
          name: "Edit question4.1.1",
          component: () => import("./views/Question/Level4/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/activity/add1/:idd",
          name: "4.1.2",
          component: () => import("./views/Activity/Level4/Add1.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/question/add1/:acivitityID",
          name: "Add question4.1.2",
          component: () => import("./views/Question/Level4/Add1.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/question/edit1/:id/:acivitityID",
          name: "Edit question4.1.2",
          component: () => import("./views/Question/Level4/Add1.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/activity/add2/:idd",
          name: "Add activity4.1.3",
          component: () => import("./views/Activity/Level4/Add2.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/question/add2/:acivitityID",
          name: "Add question4.1.3",
          component: () => import("./views/Question/Level4/Add2.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/question/edit2/:id/:acivitityID",
          name: "Edit question4.1.3",
          component: () => import("./views/Question/Level4/Add2.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/activity/add3/:idd",
          name: "Add activity4.1.4",
          component: () => import("./views/Activity/Level4/Add3.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/question/add3/:acivitityID",
          name: "Add question4.1.4",
          component: () => import("./views/Question/Level4/Add3.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/question/edit3/:id/:acivitityID",
          name: "Edit question4.1.4",
          component: () => import("./views/Question/Level4/Add3.vue"),
          beforeEnter: guard,
        },
        {
          path: "/activity/word-opposite/add",
          name: "Edit question4.2",
          component: () => import("./views/Activity/Level4/Add4.vue"),
          beforeEnter: guard,
        },
        {
          path: "/activity/word-opposite",
          name: "Edit question4.2",
          component: () => import("./views/Activity/Level4/View4.vue"),
          beforeEnter: guard,
        },
        {
          path: "/activity/word-opposite/edit/:id",
          name: "Edit oppositeWord",
          component: () => import("./views/Activity/Level4/Add4.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/activity/add5/:idd",
          name: "Add activity4.3.4",
          component: () => import("./views/Activity/Level4/Add5.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/activity/view1/:idd",
          name: "View activity4.3.6",
          component: () => import("./views/Activity/Level4/View1.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/question/add4.3.6/:acivitityID",
          name: "Add question4.3.6",
          component: () => import("./views/Question/Level4/Add7.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/question/edit4.3.6/:id/:acivitityID",
          name: "Edit question4.3.6",
          component: () => import("./views/Question/Level4/Add7.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/question/add4.3.4/:acivitityID",
          name: "Add question4.3.4",
          component: () => import("./views/Question/Level4/Add4.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/question/edit4.3.4/:id/:acivitityID",
          name: "Edit question4.3.4",
          component: () => import("./views/Question/Level4/Add4.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/activity/view3/:idd",
          name: "View activity4.4.1",
          component: () => import("./views/Activity/Level4/View3.vue"),
          beforeEnter: guard,
        },
        {
          path: "/privacy-policy",
          name: "privacy-policy",
          component: () => import("./views/Social/PrivacyPolicy.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/activity/add4.4.1",
          name: "Add activity4.4.1",
          component: () => import("./views/Activity/Level4/Add6.vue"),
          beforeEnter: guard,
        },
        {
          path: "/level4/activity/edit4.4.1/:id",
          name: "Edit question4.4.1",
          component: () => import("./views/Activity/Level4/Add6.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level1/activity/edit/:id",
          name: "Edit question1.4",
          component: () => import("./views/Activity/Level1/Add2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/question/add4.4.1/:acivitityID",
          name: "Question add4.4.1",
          component: () => import("./views/Question/Level4/Add5.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level1/question/add/1.4/:acivitityID",
          name: "Question add1.4",
          component: () => import("./views/Question/Level1/Add2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level1/question/edit/:id/:acivitityID",
          name: "Edit questionn1.4",
          component: () => import("./views/Question/Level1/Add2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/question/edit4.4.1/:id/:acivitityID",
          name: "Edit questionn4.4.1",
          component: () => import("./views/Question/Level4/Add5.vue"),
          beforeEnter: guard,
        },
        {
          path: "/about-us/add/:id",
          name: "Add about-us",
          component: () => import("./views/Setting/AboutUs.vue"),
          beforeEnter: guard,
        },
        {
          path: "/privacy-policy/add/:id",
          name: "Add privacy-policy",
          component: () => import("./views/Setting/PrivacyPolicy.vue"),
          beforeEnter: guard,
        },
        {
          path: "/sound/test",
          name: "",
          component: () => import("./views/Word/testSound.vue"),
          beforeEnter: guard,
        },

        {
          path: "/social-links/:id",
          name: "social links",
          component: () => import("./views/Setting/SocialLink.vue"),
          beforeEnter: guard,
        },

        {
          path: "/singular_plural/add",
          name: "add singular",
          component: () => import("./views/Singular/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/singular_plural/edit/:id",
          name: "Edit singular",
          component: () => import("./views/Singular/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/singular_plural/view",
          name: "add singular",
          component: () => import("./views/Singular/View.vue"),
          beforeEnter: guard,
        },
        {
          path: "/notification/add",
          name: "add notification",
          component: () => import("./views/Notification/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/notification/edit/:id",
          name: "Edit notification",
          component: () => import("./views/Notification/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/notification/view",
          name: "view notification",
          component: () => import("./views/Notification/View.vue"),
          beforeEnter: guard,
        },
        {
          path: "/question/level1/add/:acivitityID",
          name: "Question add1.3.2",
          component: () => import("./views/Question/Level1/Add.vue"),
          beforeEnter: guard,
        },
        {
          path: "/question/level1/edit/:id/:acivitityID",
          name: "Edit question1.3.1",
          component: () => import("./views/Question/Level1/Add.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/activity/:idd",
          name: "View activity4.2",
          component: () => import("./views/Activity/Level4/View2.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/question/add/4.2/:acivitityID",
          name: "Question add4.2",
          component: () => import("./views/Question/Level4/Add6.vue"),
          beforeEnter: guard,
        },

        {
          path: "/level4/question/edit/4.2/:id/:acivitityID",
          name: "Edit 4.2question",
          component: () => import("./views/Question/Level4/Add6.vue"),
          beforeEnter: guard,
        },

        {
          path: "/character/word/:characterID",
          name: "View Words",
          component: () => import("./views/Word/Character.word.vue"),
          beforeEnter: guard,
        },
      ],
    },
    {
      path: "/",
      redirect: "login",
      component: AuthLayout,
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("./views/Login.vue"),
          meta: { middleware: guest },
          beforeEnter: guestt,
        },

        // {
        //   path: "/register",
        //   name: "register",
        //   component: () => import("./views/Register.vue"),
        //   meta: { middleware: guest },
        // },
        // {
        //   path: "/password/reset",
        //   name: "PasswordReset",
        //   component: PasswordReset,
        //   meta: { middleware: guest },
        // },
        // {
        //   path: "/password/email",
        //   name: "PasswordEmail",
        //   component: PasswordEmail,
        //   meta: { middleware: guest },
        // },
      ],
    },
    // {
    //   path: "/examples",
    //   component: DashboardLayout,
    //   name: "Examples",
    //   children: [
    //     {
    //       path: "user-management/list-users",
    //       name: "List Users",
    //       components: { default: ListUserPage },
    //       meta: { middleware: auth },
    //     },
    //   ],
    // },
  ],
});

export default router;
