import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueRouter from "vue-router";
import store from "./store";
import "./registerServiceWorker";
import ArgonDashboard from "./plugins/argon-dashboard";
import axios from "axios";
import VueAxios from "vue-axios";
import IsDemo from "./plugins/isDemo";
import { library } from "@fortawesome/fontawesome-svg-core";

import vSelect from "vue-select";
import "./assets/css/style.css";
import "vue-select/dist/vue-select.css";

import VueToast from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue } from "bootstrap-vue";
import loader from "vue-ui-preloader";
import Vuelidate from "vuelidate";
import CKEditor from "@ckeditor/ckeditor5-vue2";

/* import specific icons */
import {
  faPencil,
  faTrashCan,
  faEye,
  faChevronDown,
  faRightFromBracket,
  faAdd,
  faToggleOff,
  faToggleOn,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
/* import font awesome icon component */

import VueAudio from "vue-audio-better";

Vue.use(VueAudio);
/* add icons to the library */
library.add(
  faPencil,
  faTrashCan,
  faChevronDown,
  faAdd,
  faToggleOff,
  faToggleOn,
  faRightFromBracket,
  faEye,
  faPlay
);
/* add font awesome icon component */

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("v-select", vSelect);

Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(IsDemo);
Vue.use(BootstrapVue);
Vue.use(loader);
Vue.use(VueToast);
Vue.prototype.$activity1 = "1.3.4";
Vue.prototype.$activity2 = "2.1.4";
Vue.prototype.$activity9 = "2.2.3";
Vue.prototype.$activity8 = "2.3.1";
Vue.prototype.$activity3 = "2.4.2";
Vue.prototype.$activity4 = "3.1.2";
Vue.prototype.$activity5 = "3.2.2";
Vue.prototype.$activity6 = "3.3.4";
Vue.prototype.$activity7 = "3.4";
Vue.prototype.$activity10 = "4.1.1";
Vue.prototype.$activity11 = "4.1.2";
Vue.prototype.$activity12 = "4.1.3";
Vue.prototype.$activity13 = "4.1.4";
Vue.prototype.$activity14 = "4.3.4";
Vue.prototype.$activity19 = "4.3.6";
Vue.prototype.$activity15 = "4.4.1";
Vue.prototype.$activity16 = "1.3.2";
Vue.prototype.$activity17 = "4.2";
Vue.prototype.$activity18 = "1.4";

// Vue.prototype.$appName = "https://ymt-testing.crunchyapps.com";
Vue.prototype.$appName='https://ymt-staging-backend.crunchyapps.com'
// Vue.prototype.$appName = "https://backend-testing.ymt.app";
// Vue.prototype.$appName = "https://ymt.app";
// Vue.prototype.$appName = "http://localhost:8000";

Vue.config.productionTip = false;
Vue.use(ArgonDashboard);
Vue.use(CKEditor);

const app = new Vue({
  router,
  store: store,
  el: "#app",
  render: (h) => h(App),
});

store.$app = app;
