<template>
  <base-nav
    class="navbar-top navbar-white"
    style="background: white; height: 56px"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="
        navbar-search navbar-search-dark
        form-inline
        mr-3
        d-none d-md-flex
        ml-lg-auto
      "
      style="visibility: hidden"
    >
      <div class="form-group mb-0">
        <base-input
          placeholder="Search"
          class="input-group-alternative"
          alternative=""
          addon-right-icon="fas fa-search"
        >
        </base-input>
      </div>
    </form>

    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <!-- <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        menu-classes="dropdown-menu dropdown-menu-right py-0 overflow-hidden"
      > -->
      <a href="#" slot="title" class="nav-link pr-0" @click.prevent>
        <div class="media align-items-center">
          <div class="media-body ml-2 d-none d-lg-block">
            <a
              href=""
              @click.prevent="logout"
              style="color: black; text-decoration: none"
            >
              <span class="text-dark">Log out</span> &nbsp;

              <font-awesome-icon
                style="font-size: 16px; padding: 0 5px"
                icon="fa-right-from-bracket"
              />
              <!-- <span>Logout</span> -->
            </a>
          </div>
        </div>
      </a>
      <!-- </base-dropdown> -->
    </ul>
  </base-nav>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      title: "Profile",
      avatar: null,
    };
  },
  async created() {
    // this.$store.watch(
    //   () => this.$store.getters["profile/me"],
    //   (me) => {
    //     this.title = me.name;
    //     this.avatar = me.profile_image;
    //   }
    // );
    // await this.$store.dispatch("profile/me");
  },
  computed: {
    profileImage() {
      return this.avatar ? this.avatar : "/img/placeholder.jpg";
    },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      axios.post(this.$appName + "/api/logout").then(() => {
        localStorage.removeItem("users");
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>
