<template>
  <div class="main-content bg-default" style="background: white !important;">
    <div class="container" style="">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
    <!-- Navbar -->

    <!-- Header -->

    <!-- Page content -->

    <footer
      class=""
      id="footer-main"
      style="  position: fixed;
    bottom: 0;
  width:100%
    right: 0;
    margin: 0;
    padding: 30px 20px;"
    >
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-12 pr-1">
            <div class="copyright text-center text-lg-right text-muted">
              © {{ year }}, made with <i class="fa fa-heart heart"></i> by<a
                class="font-weight-bold ml-1"
                target="_blank"
                rel="noopener"
                >YMT</a
              >

              for a better web.
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  computed: {
    isLogin() {
      return this.$route.name === "login";
    },
    isRegister() {
      return this.$route.name === "register";
    },
    isPassword() {
      return this.$route.name === "PasswordReset";
    },
  },
};
</script>
<style>
.align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
