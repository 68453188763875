<template>
  <div class="wrapper" :class="[{ 'nav-open': $sidebar.showSidebar }]">
    <side-bar :background-color="sidebarBackground" short-title="" title="">
      <template slot="links">
        <!-- <div class="collapse navbar-collapse" id="navigation"> -->
        <!-- <ul class="navbar-nav mx-auto">
            <li class="nav-item"> -->
        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/dashboard"
        >
          <i class="ni ni-tv-2 text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Dashboard</span>
        </router-link>
        <!-- </li> -->

        <!-- <li class="nav-item"> -->
        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/user"
        >
          <i class="ni ni-single-02 text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">User</span>
        </router-link>
        <!-- </li> -->

        <!-- <li class="nav-item"> -->
        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/word"
        >
          <i class="ni ni-caps-small text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Word</span>
        </router-link>
        <!-- </li> -->

        <!-- <li class="nav-item"> -->
        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/level"
        >
          <i class="ni ni-support-16 text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Level</span>
        </router-link>
        <!-- </li> -->

        <!-- <li class="nav-item active"> -->
        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/category"
        >
          <i class="ni ni-bullet-list-67 text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Category</span>
        </router-link>
        <!-- </li> -->

        <!-- <li class="nav-item"> -->
        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/level3/activity/add1"
        >
          <i class="ni ni-html5 text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Number</span>
        </router-link>
        <!-- </li> -->

        <!-- <li class="nav-item"> -->
        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/singular_plural/view"
        >
          <i class="ni ni-single-copy-04 text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Singular Plural</span>
        </router-link>

        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/activity/word-opposite"
        >
          <i class="ni ni-caps-small text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Word Opposite</span>
        </router-link>

          
        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/notification/add"
        >
          <i class="ni ni-bell-55 text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Notification</span>
        </router-link>

        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/notification/view"
        >
          <i class="ni ni-calendar-grid-58 text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Push History</span>
        </router-link>

 

   
    
        <sidebar-item
          :link="{
            name: 'Activity',
            path: '/level',
            icon: 'ni ni-user-run text-white',
          }"
        >
          <router-link
            class="dropownLink nav-link active"
            to="/activity/add/level1/1.3.2"
          >
            <span class="nav-link-textt">1.3.2</span>
          </router-link>
          <router-link
            class="dropownLink nav-link active"
            to="/activity/add/1.3.4"
          >
            <span class="nav-link-textt">1.3.4</span>
          </router-link>
          <router-link
            class="dropownLink nav-link active"
            to="/level1/activity/view/1.4"
          >
            <span class="nav-link-textt">1.4</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/activity/add/5/2.2.3"
          >
            <span class="nav-link-textt">2.2.3</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/activity/add/4/2.3.1"
          >
            <span class="nav-link-textt">2.3.1</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/activity/add/2/2.4.2"
          >
            <span class="nav-link-textt">2.4.2</span>
          </router-link>
          <router-link
            class="dropownLink nav-link active"
            to="/activity/add/3/3.1.2"
          >
            <span class="nav-link-textt">3.1.2</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/level3/activity/add/3.2.2"
          >
            <span class="nav-link-textt">3.2.2</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/level3/activity/add2/3.3.4"
          >
            <span class="nav-link-textt">3.3.4</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/level3/activity/view3/3.4"
          >
            <span class="nav-link-textt">3.4</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/level4/activity/add/4.1.1"
          >
            <span class="nav-link-textt">4.1.1</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/level4/activity/add1/4.1.2"
          >
            <span class="nav-link-textt">4.1.2</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/level4/activity/add2/4.1.3"
          >
            <span class="nav-link-textt">4.1.3</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/level4/activity/add3/4.1.4"
          >
            <span class="nav-link-textt">4.1.4</span>
          </router-link>
          <router-link
            class="dropownLink nav-link active"
            to="/level4/activity/4.2"
          >
            <span class="nav-link-textt">4.2</span>
          </router-link>
          <router-link
            class="dropownLink nav-link active"
            to="/level4/activity/add5/4.3.4"
          >
            <span class="nav-link-textt">4.3.4</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/level4/activity/view1/4.3.6"
          >
            <span class="nav-link-textt">4.3.6</span>
          </router-link>

          <router-link
            class="dropownLink nav-link active"
            to="/level4/activity/view3/4.4.1"
          >
            <span class="nav-link-textt">4.4.1</span>
          </router-link>

          <!-- <sidebar-item
            :link="{
              name: '1.3.2',
              path: '/activity/add/level1/1.3.2',
            }"
          />

          <sidebar-item
            :link="{
              name: '1.3.4',
              path: '/activity/add/1.3.4',
            }"
          /> -->

          <!-- <sidebar-item
            :link="{
              name: '1.4',
              path: '/level1/activity/view/1.4',
            }"
          />

          <sidebar-item
            :link="{
              name: '2.1.4',
              path: '/activity/add1/2.1.4',
            }"
          />
          <sidebar-item
            :link="{
              name: '2.2.3',
              path: '/activity/add/5/2.2.3',
            }"
          />

          <sidebar-item
            :link="{
              name: '2.3.1',
              path: '/activity/add/4/2.3.1',
            }"
          />

          <sidebar-item
            :link="{
              name: '2.4.2',
              path: '/activity/add/2/2.4.2',
            }"
          /> -->

          <!-- <sidebar-item
            :link="{
              name: '3.1.2',
              path: '/activity/add/3/3.1.2',
            }"
          />

          <sidebar-item
            :link="{
              name: '3.2.2',
              path: '/level3/activity/add/3.2.2',
            }"
          />
          <sidebar-item
            :link="{
              name: '3.3.4',
              path: '/level3/activity/add2/3.3.4',
            }"
          />
          <sidebar-item
            :link="{
              name: '3.4',
              path: '/level3/activity/view3/3.4',
            }"
          /> -->

          <!-- <sidebar-item
            :link="{
              name: '4.1.1',
              path: '/level4/activity/add/4.1.1',
            }"
          />

          <sidebar-item
            :link="{
              name: '4.1.2',
              path: '/level4/activity/add1/4.1.2',
            }"
          />

          <sidebar-item
            :link="{
              name: '4.1.3',
              path: '/level4/activity/add2/4.1.3',
            }"
          /> -->

          <!-- <sidebar-item
            :link="{
              name: '4.1.4',
              path: '/level4/activity/add3/4.1.4',
            }"
          />

          <sidebar-item
            :link="{
              name: '4.2',
              path: '/level4/activity/4.2',
            }"
          />

          <sidebar-item
            :link="{
              name: '4.3.4',
              path: '/level4/activity/add5/4.3.4',
            }"
          /> -->

          <sidebar-item />
        </sidebar-item>

        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/social-links/1"
        >
          <i class="ni ni-settings text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Setting</span>
        </router-link>

        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/privacy-policy/add/3"
        >
          <i class="ni ni-lock-circle-open text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">Privacy Policy</span>
        </router-link>

        <router-link
          class="nav-link d-flex align-items-center me-2 active"
          aria-current="page"
          to="/about-us/add/2"
        >
          <i class="ni ni-collection text-white" aria-hidden="true"></i>
          <span class="nav-link-textt">About Us</span>
        </router-link>
        <!-- <sidebar-item
          :link="{
            name: 'Setting',
            path: '/social-links/1',
            icon: 'ni ni-settings text-white',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Privacy Policy',
            path: '/privacy-policy/add/3',
            icon: 'ni ni-lock-circle-open text-white',
          }"
        />

        <sidebar-item
          :link="{
            name: 'About Us',
            path: '/about-us/add/2',
            icon: 'ni ni-collection text-white',
          }"
        /> -->
        <!-- 
        <sidebar-item
          :link="{
            name: 'Icons',
            path: '/icons',
          }"
        /> -->

        <hr
          class="my-3"
          style="
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            min-width: 80%;
            overflow: visible;
            box-sizing: content-box;
            height: 0;
          "
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  mounted() {
    // var hours = 24;
    // var now = new Date().getTime();
    // var setupTime = localStorage.getItem("users1");

    // if (setupTime == null) {
    //   localStorage.setItem("users1", now);
    // } else {
    //   if (now - setupTime > hours * 60 * 60 * 1000) {
    //     localStorage.clear();
    //     localStorage.setItem("", now);
    //   }
    // }
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
